import React from 'react';
import { useTranslation } from 'react-i18next';
import camelCase from 'camelcase';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { makeStyles, createStyles } from '@material-ui/styles';
import type { Theme } from '@material-ui/core';

import { Flex1, GrayInnerContainer, RecapTitle, Row } from 'styles/ReusabledStyledComponents';

import type {
    UserJournalRecap,
    JOURNAL_RESPONSE_TYPES,
    JournalQuestionKeys,
    JournalResponseKeys,
} from 'types/userJournal';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subheading: {
            fontWeight: 'bold',
            width: '13rem',
        },
        response: {
            paddingLeft: '2rem',
        },
    }),
);

interface IProps {
    userJournalsRecap: UserJournalRecap;
}

const QuestionAverages: React.FC<IProps> = ({ userJournalsRecap }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const questionTypesArray = Object.keys(userJournalsRecap) as JournalQuestionKeys[];
    return (
        <Container>
            {questionTypesArray.map((question) => {
                const questionName = camelCase(question) as JournalQuestionKeys;
                const title = `MaConso.${questionName}.title`;
                const questionResponse = userJournalsRecap[question] as JOURNAL_RESPONSE_TYPES;
                const responseNames = Object.keys(questionResponse) as JournalResponseKeys[];
                return (
                    <Row key={question}>
                        <Typography className={classes.subheading}>{`${t(title)}:`}</Typography>
                        <Container>
                            {responseNames.map((responseName) => {
                                const value = questionResponse[responseName];
                                const responseKey = `MaConso.${questionName}.questions.${responseName}`;
                                return (
                                    <Typography className={classes.response}>
                                        {t(responseKey, { value })}
                                    </Typography>
                                );
                            })}
                        </Container>
                    </Row>
                );
            })}
        </Container>
    );
};

export default QuestionAverages;
