import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import type { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

// Types

interface IProps {
    message?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginVertical: 12,
            paddingVertical: 12,
            backgroundColor: '#F6F6F6',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title: {
            fontSize: theme.typography.pxToRem(15),
            padding: 12,
            textAlign: 'center',
        },
    }),
);

const IncompleteMessage: React.FC<IProps> = ({ message }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>
                {message ?? t('UserDetails.incomplete')}
            </Typography>
        </div>
    );
};

export default IncompleteMessage;
