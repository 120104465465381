import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { axiosInstance } from 'axiosInstance';
import { API_ROUTES } from 'constants/api';
import { SliceStatus } from 'types';
import type { ApiErrorCode } from 'types/errorCodes';
import { isApiError } from 'types/errorCodes';
import type { User, UserProfile } from 'types/user';
import type { RootState } from '../store';

export interface IUserState {
    selectedUser: User | null; // user that we are doing CRUD on
    status: SliceStatus;
    error?: string | null;
}

export const initialState: IUserState = {
    status: SliceStatus.IDLE,
    selectedUser: null,
};

interface FetchUserSuccessResponse {
    user: User;
}

interface UpdateUserProfileResponse {
    profile: UserProfile;
}

interface UpdateUserResponse {
    user: User;
}

export const fetchUser = createAsyncThunk<
    FetchUserSuccessResponse,
    { userId: number },
    { rejectValue: ApiErrorCode }
>('user/fetchUser', async (params: { userId: number }, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get<FetchUserSuccessResponse>(
            `${API_ROUTES.USERS}${params.userId}/details`,
        );
        return response.data;
    } catch (err: unknown) {
        let apiErrorCode: ApiErrorCode = 'Generic';

        if (axios.isAxiosError(err) && err.response && isApiError(err.response.data)) {
            const body = err.response.data;
            apiErrorCode = body.errorCode;
        }

        return rejectWithValue(apiErrorCode);
    }
});

export const updateUserProfile = createAsyncThunk<
    UpdateUserProfileResponse,
    {
        values: {
            firstName?: string;
            lastName?: string;
            onboardingCompleted?: boolean;
            notificationsEnabled?: boolean;
        };
    },
    { rejectValue: ApiErrorCode }
>(
    'auth/update-user-profile',
    async (
        params: {
            values: {
                firstName?: string;
                lastName?: string;
                onboardingCompleted?: boolean;
                notificationsEnabled?: boolean;
            };
        },
        { dispatch, rejectWithValue, getState },
    ) => {
        const state = getState() as RootState;
        const userId = state.userSlice.selectedUser?.id ?? -1;
        const { values } = params;

        try {
            const userProfileEndpoint = API_ROUTES.USER_PROFILE.replace(':userId', String(userId));
            const response = await axiosInstance.put<UpdateUserProfileResponse>(
                `${userProfileEndpoint}/${state.userSlice.selectedUser?.profile.id}`,
                {
                    ...values,
                },
            );
            return response.data;
        } catch (err: unknown) {
            let apiErrorCode: ApiErrorCode = 'Generic';

            if (axios.isAxiosError(err) && err.response && isApiError(err.response.data)) {
                const data = err.response.data;
                apiErrorCode = data.errorCode;
            }
            return rejectWithValue(apiErrorCode);
        }
    },
);

export const updateUser = createAsyncThunk<
    UpdateUserResponse,
    {
        isActive: boolean;
        profile?: {
            firstName: string;
            lastName: string;
        };
    },
    { rejectValue: ApiErrorCode }
>(
    'auth/update-user',
    async (
        params: {
            isActive: boolean;
            profile?: {
                firstName: string;
                lastName: string;
            };
        },
        { dispatch, rejectWithValue, getState },
    ) => {
        const state = getState() as RootState;
        const user = state.userSlice.selectedUser;

        const { isActive, profile } = params;

        try {
            const updateUserEndpoint = API_ROUTES.USERS.replace(':userId', String(user?.id));

            const response = await axiosInstance.put<UpdateUserResponse>(
                `${updateUserEndpoint}${user?.id}`,
                {
                    isActive,
                    profile: {
                        ...profile,
                        id: user?.profile.id,
                    },
                },
            );

            return response.data;
        } catch (err: unknown) {
            let apiErrorCode: ApiErrorCode = 'Generic';

            if (axios.isAxiosError(err) && err.response && isApiError(err.response.data)) {
                const data = err.response.data;
                apiErrorCode = data.errorCode;
            }
            return rejectWithValue(apiErrorCode);
        }
    },
);

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchUser.pending, (state) => {
            state.status = SliceStatus.LOADING;
        });
        builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
            state.selectedUser = payload.user;
            state.status = SliceStatus.SUCCEDED;
            state.error = undefined;
        });
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.status = SliceStatus.FAILED;
            state.error = action.payload;
            state.selectedUser = null;
        });
        builder.addCase(updateUserProfile.pending, (state) => {
            state.status = SliceStatus.LOADING;
        });
        builder.addCase(updateUserProfile.fulfilled, (state, { payload }) => {
            if (state.selectedUser) {
                state.selectedUser.profile = payload.profile;
            }
            state.status = SliceStatus.SUCCEDED;
            state.error = undefined;
        });
        builder.addCase(updateUserProfile.rejected, (state, action) => {
            state.status = SliceStatus.FAILED;
            state.error = action.payload;
            state.selectedUser = null;
        });
        builder.addCase(updateUser.pending, (state) => {
            state.status = SliceStatus.LOADING;
        });
        builder.addCase(updateUser.fulfilled, (state, { payload }) => {
            if (state.selectedUser) {
                state.selectedUser = payload.user;
            }
            state.status = SliceStatus.SUCCEDED;
            state.error = undefined;
        });
        builder.addCase(updateUser.rejected, (state, action) => {
            state.status = SliceStatus.FAILED;
            state.error = action.payload;
            state.selectedUser = null;
        });
    },
});

export const selectSelectedUser = (state: RootState) => state.userSlice.selectedUser;
export default userSlice.reducer;
