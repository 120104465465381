import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { axiosInstance } from 'axiosInstance';
import { API_ROUTES } from 'constants/api';
import { SliceStatus } from 'types';
import type { ApiErrorCode } from 'types/errorCodes';
import { isApiError } from 'types/errorCodes';
import type { CreateUserType } from 'types/user';

import type { RootState } from '../store';

export interface ICreateUserState {
    status: SliceStatus;
    error?: ApiErrorCode;
}

const initialState: ICreateUserState = {
    status: SliceStatus.IDLE,
};

export const createUser = createAsyncThunk<
    undefined,
    CreateUserType,
    { rejectValue: ApiErrorCode }
>('createUser/create', async (user, { rejectWithValue }) => {
    try {
        await axiosInstance.post(API_ROUTES.USERS, { user });
        return undefined;
    } catch (err: unknown) {
        let apiErrorCode: ApiErrorCode = 'Generic';

        if (axios.isAxiosError(err) && err.response && isApiError(err.response.data)) {
            const responseBody = err.response.data;
            apiErrorCode = responseBody.errorCode;
        }

        return rejectWithValue(apiErrorCode);
    }
});

const slice = createSlice({
    name: 'createUser',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(createUser.pending, (state) => {
            state.status = SliceStatus.LOADING;
        });
        builder.addCase(createUser.fulfilled, (state) => {
            state.status = SliceStatus.SUCCEDED;
            state.error = undefined;
        });
        builder.addCase(createUser.rejected, (state, action) => {
            state.status = SliceStatus.FAILED;
            state.error = action.payload;
        });
    },
});

export const selectIsCreateUserLoading = (state: RootState) =>
    state.createUserSlice.status === SliceStatus.LOADING;

export default slice.reducer;
