import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

interface IProps {
    title: string;
}

const Container = styled.div`
    margin-horizontal: 2rem;
    display: flex;
    flex: 1;
    padding: 1rem;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subheading: {
            fontWeight: 'bold',
        },
    }),
);

const AccordianHeader: React.FC<IProps> = ({ title }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container>
            <Typography className={classes.subheading}>{t(title)}</Typography>
        </Container>
    );
};

export default AccordianHeader;
