import React from 'react';
import { useDispatch } from 'react-redux';

import { loginUser, selectAuthError, selectIsAuthLoading } from 'slices/authSlice';
import { useAppSelector } from 'store';
import type { ApiErrorCode } from 'types/errorCodes';

interface AuthContextProps {
    login: (email: string, password: string) => void;
    isLoading: boolean;
    error: string | null;
}

const AuthContext = React.createContext<Partial<AuthContextProps>>({});

const AuthProvider: React.FunctionComponent = ({ children }) => {
    const dispatch = useDispatch();

    const login = (email: string, password: string) => {
        dispatch(loginUser({ email, password }));
    };

    const isLoading = useAppSelector(selectIsAuthLoading);
    const error = useAppSelector(selectAuthError);

    return (
        <AuthContext.Provider value={{ login, isLoading, error }}>{children}</AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = React.useContext(AuthContext);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Context might be undefined
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }

    return context;
};

export { AuthProvider, useAuth };
