import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import type { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { GrayInnerContainer, RecapTitle, Row } from 'styles/ReusabledStyledComponents';

interface IProps {
    title: string;
    startedAt: string | null;
    completedAt: string | null;
    lastViewedAt: string | null;
    totalViews: number | null;
    userResponses: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    flex: 1;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 'bolder',
        },
    }),
);

const UserElementHeader: React.FC<IProps> = ({
    title,
    startedAt,
    completedAt,
    lastViewedAt,
    totalViews,
    userResponses,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <GrayInnerContainer>
                <RecapTitle>
                    <Typography className={classes.title}>{title}</Typography>
                </RecapTitle>
                <Row>
                    <Container>
                        <Typography>{t('UserDetails.startedAt')}</Typography>
                        <Typography variant="caption">
                            {startedAt ? new Date(startedAt).toLocaleString('en-gb') : '--'}
                        </Typography>
                    </Container>
                    <Container>
                        <Typography>{t('UserDetails.completedAt')}</Typography>
                        <Typography variant="caption">
                            {completedAt ? new Date(completedAt).toLocaleString('en-gb') : '--'}
                        </Typography>
                    </Container>
                    <Container>
                        <Typography>{t('UserDetails.lastViewedAt')}</Typography>
                        <Typography variant="caption">
                            {lastViewedAt ? new Date(lastViewedAt).toLocaleString('en-gb') : '--'}
                        </Typography>
                    </Container>
                    <Container>
                        <Typography>
                            {t('UserDetails.totalViews', { totalViews: totalViews ?? '--' })}
                        </Typography>
                    </Container>
                </Row>
            </GrayInnerContainer>
            {userResponses && <Divider />}
        </>
    );
};

export default UserElementHeader;
