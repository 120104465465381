import React from 'react';
import { useTranslation } from 'react-i18next';
import { addSeconds, format } from 'date-fns';
import Typography from '@material-ui/core/Typography';

import type { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Flex1, Row } from 'styles/ReusabledStyledComponents';

// Types

interface IProps {
    title: string;
    startedAt: string | null;
    completedAt: string | null;
    totalTime: number;
    version: number;
    challengeOrder: number | null;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subheading: {
            fontWeight: 'bolder',
        },
    }),
);

const UserSegmentHeader: React.FC<IProps> = ({ startedAt, completedAt, totalTime }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getDuration = (segmentTotalTime: number) => {
        const helperDate: Date = addSeconds(new Date(0), segmentTotalTime);

        const timeMinutesSeconds = format(helperDate, 'mm:ss');

        const duration = t('UserDetails.duration', { timeMinutesSeconds });
        return duration;
    };

    return (
        <Row>
            <Flex1>
                <Typography className={classes.subheading}>{t('UserDetails.startedAt')}</Typography>
                <Typography variant="caption">
                    {startedAt ? new Date(startedAt).toLocaleString('en-gb') : '--'}
                </Typography>
            </Flex1>
            <Flex1>
                <Typography className={classes.subheading}>
                    {t('UserDetails.completedAt')}
                </Typography>
                <Typography variant="caption">
                    {completedAt ? new Date(completedAt).toLocaleString('en-gb') : '--'}
                </Typography>
            </Flex1>

            <Flex1>
                <Typography className={classes.subheading}>{t('UserDetails.totalTime')}</Typography>
                <Typography variant="caption">{getDuration(totalTime)}</Typography>
            </Flex1>
        </Row>
    );
};

export default UserSegmentHeader;
