import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import type { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import UserElementHeader from 'components/recaps/UserElementHeader';

// Types
import type { UserElement, SegmentType, UserChallenge } from 'types/userSegment';
import { ElementType } from 'types/userSegment';

// Helpers
import { getTranslationBase } from 'helpers/translation-helpers';

interface IProps {
    segmentType: SegmentType;
    userElement: UserElement;
    userChallenge: UserChallenge | null;
}

const QandAContainer = styled.div`
    padding: 3rem;
    align-items: center;
    max-width: 800;
`;

const ChallengeContainer = styled.div`
    margin-top: 1rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
`;

const ResponseContainer = styled.div`
margin-left: 2rem,
margin-bottom: 2rem,
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: 'bold',
        },
    }),
);

const UserElementRecap: React.FC<IProps> = ({ segmentType, userElement, userChallenge }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getQuestionText = (questionNumber: number) => {
        const keyBase = getTranslationBase(segmentType, userElement);
        const questionText = t(`${keyBase}.question${questionNumber}.text`);
        return questionText;
    };

    const getResponseText = (questionNumber: number, responseTranslationKey: string) => {
        const keyBase = getTranslationBase(segmentType, userElement);
        const responseText = t(`${keyBase}.question${questionNumber}.${responseTranslationKey}`);
        return responseText;
    };
    return (
        <>
            <UserElementHeader
                title={t('UserDetails.moduleNumber', { number: userElement.order + 1 })}
                startedAt={userElement.startedAt}
                completedAt={userElement.completedAt}
                lastViewedAt={userElement.lastViewedAt ? userElement.lastViewedAt : null}
                totalViews={userElement.totalViews ?? null}
                userResponses={Boolean(
                    userElement.userElementResponses &&
                        userElement.userElementResponses.length >= 1,
                )}
            />
            {userElement.userElementResponses && userElement.userElementResponses.length >= 1 && (
                <QandAContainer>
                    <Typography className={classes.heading}>
                        {getQuestionText(userElement.userElementResponses[0].questionNumber)}
                    </Typography>
                    <ResponseContainer>
                        {userElement.userElementResponses.map((response, index) => (
                            <Typography
                                variant="caption"
                                key={`${response.userElementId}_${response.translationKey}_${response.questionNumber}`}
                            >
                                {`${index + 1}. ${
                                    response.input ??
                                    getResponseText(
                                        response.questionNumber,
                                        response.translationKey,
                                    )
                                }`}
                            </Typography>
                        ))}
                    </ResponseContainer>
                </QandAContainer>
            )}
            {userElement.elementType === ElementType.CHALLENGE_FEEDBACK && userChallenge && (
                <QandAContainer>
                    <Typography className={classes.heading}>
                        {t(`${getTranslationBase(segmentType, userElement)}.title`)}
                    </Typography>
                    <ResponseContainer>
                        <Typography variant="caption">
                            {t(
                                `${getTranslationBase(segmentType, userElement)}.${
                                    userChallenge.status
                                }`,
                            )}
                        </Typography>
                    </ResponseContainer>
                </QandAContainer>
            )}
            {userElement.elementType === ElementType.SMART_DEADLINE && userChallenge && (
                <QandAContainer>
                    <Typography className={classes.heading}>
                        {t(`${getTranslationBase(segmentType, userElement)}.text`)}
                    </Typography>
                    <ChallengeContainer>
                        <Typography className={classes.heading}>
                            {t(`${getTranslationBase(segmentType, userElement)}.whenToStartTitle`)}
                        </Typography>
                        {userChallenge.startDate && (
                            <Typography variant="caption">
                                {new Date(userChallenge.startDate).toLocaleString('en-gb')}
                            </Typography>
                        )}
                    </ChallengeContainer>
                    <ChallengeContainer>
                        <Typography className={classes.heading}>
                            {t(`${getTranslationBase(segmentType, userElement)}.deadlineTitle`)}
                        </Typography>
                        {userChallenge.endDate && (
                            <Typography variant="caption">
                                {new Date(userChallenge.endDate).toLocaleString('en-gb')}
                            </Typography>
                        )}
                    </ChallengeContainer>
                </QandAContainer>
            )}
        </>
    );
};

export default UserElementRecap;
