import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import { colours } from 'constants/colours';

export const ErrorMsg = styled(Typography)`
    color: red;
    font-size: 0.75rem;
`;

export const PageContainer = styled.section`
    grid-area: content;
    display: flex;
    flex: 1;
    background-color: ${colours.grey['100']};
    justify-content: center;
    width: 100%;
    align-items: center;
`;

export const PaperContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 4rem 4rem;
    min-width: 40%;
    border-radius: 10px;
    align-items: center;
`;

export const Row = styled.div`
    flex-direction: row;
    display: flex;
`;

export const Flex1 = styled.div`
    flex: 1;
`;

export const TitleSideLine = styled.div`
    flex: 2 1 0%;
    height: 0.01rem;
    background-color: rgb(176, 190, 197);
    align-item: center;
    margin: 1rem;
`;

export const RecapTitle = styled.div`
    align-items: 'center';
    padding-top: 10;
    padding-bottom: 10;
`;

export const GrayInnerContainer = styled.div`
    background-color: ${colours.grey[100]};
    padding: 1rem;
`;

export const TextLink = styled.a({
    color: colours.black,
    fontSize: '.8rem',
});
