import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Paper, Typography } from '@material-ui/core';

import { colours } from 'constants/colours';
import { PageContainer, PaperContainer } from 'styles/ReusabledStyledComponents';

const Form = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const CURRENT_PAGE = 'PasswordChangedPage';

const PasswordChangedPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <>
            <Helmet>
                <title>{t(`${CURRENT_PAGE}.helmetTitle`)}</title>
            </Helmet>
            <PageContainer data-testid="create_password_page">
                <PaperContainer square={true}>
                    <Typography>{t(`${CURRENT_PAGE}.title`)}</Typography>
                </PaperContainer>
            </PageContainer>
        </>
    );
};

export default PasswordChangedPage;
