import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { axiosInstance } from 'axiosInstance';
import { API_ROUTES } from 'constants/api';
import { SliceStatus } from 'types';
import type { ApiErrorCode, ServerErrorCode } from 'types/errorCodes';
import { isServerError, isApiError } from 'types/errorCodes';
import type { RolePermissions, User, UserWithPermissions } from 'types/user';
import type { RootState } from '../store';

export interface IAuthState {
    user?: UserWithPermissions;
    token?: string;
    status: SliceStatus;
    error?: string | null;
    updatePasswordState: SliceStatus;
}
interface IResetPassword {
    token: string;
    password: string;
}

const initialState: IAuthState | undefined = {
    status: SliceStatus.IDLE,
    updatePasswordState: SliceStatus.IDLE,
};

interface LoginSuccessResponse {
    user: User;
    token: string;
    permissions: RolePermissions;
}

export const loginUser = createAsyncThunk<
    LoginSuccessResponse,
    { email: string; password: string },
    { rejectValue: ApiErrorCode }
>('authSlice/loginUser', async (params, { rejectWithValue }) => {
    const body = {
        email: params.email,
        password: params.password,
    };

    try {
        const response = await axiosInstance.post<LoginSuccessResponse>(API_ROUTES.LOGIN, body);
        const webAppPermission = response.data.permissions.webApp;
        if (!webAppPermission) {
            return rejectWithValue('NoPermission');
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, require-atomic-updates -- Required
            axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.token}`;

            return response.data;
        }
    } catch (err: unknown) {
        let apiErrorCode: ApiErrorCode = 'Generic';

        if (axios.isAxiosError(err) && err.response && isApiError(err.response.data)) {
            const data = err.response.data;
            apiErrorCode = data.errorCode;
        }
        return rejectWithValue(apiErrorCode);
    }
});

export const resetPassword = createAsyncThunk<
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- Safe to ignore
    void,
    IResetPassword,
    {
        rejectValue: ServerErrorCode;
    }
    // eslint-disable-next-line consistent-return -- Safe to ignore
>('auth/reset-password', async ({ token, password }, { rejectWithValue }) => {
    try {
        await axiosInstance.put(API_ROUTES.RESET_PASSWORD, { token, password });
    } catch (err: unknown) {
        let serverError: ServerErrorCode = 'Generic';

        if (axios.isAxiosError(err) && err.response && isServerError(err.response.data)) {
            const data = err.response.data;
            serverError = data.errorCode;
        }
        return rejectWithValue(serverError);
    }
});

export const logout = createAsyncThunk('authSlice/logout', () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, -- Required
    axiosInstance.defaults.headers.common.Authorization = '';
});

export const resetUpdatePasswordState = createAsyncThunk(
    'authSlice/resetUpdatePasswordState',
    (_, { dispatch }) => {
        dispatch(resetUpdatePasswordState);
    },
);

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setUserToUndefined(state) {
            state.user = undefined;
        },
        setUserErrorsAction(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },
        resetUpdatePasswordAction(state) {
            state.updatePasswordState = initialState.updatePasswordState;
        },
    },
    extraReducers(builder) {
        builder.addCase(loginUser.pending, (state) => {
            state.status = SliceStatus.LOADING;
        });
        builder.addCase(loginUser.fulfilled, (state, { payload }) => {
            state.user = { ...payload.user, permissions: payload.permissions };
            state.token = payload.token;
            state.status = SliceStatus.SUCCEDED;
            state.error = undefined;
        });
        builder.addCase(loginUser.rejected, (state, action) => {
            state.status = SliceStatus.FAILED;
            state.error = action.payload;
            state.user = undefined;
            state.token = undefined;
        });

        builder.addCase(resetPassword.pending, (state) => {
            state.updatePasswordState = SliceStatus.LOADING;
        });
        builder.addCase(resetPassword.fulfilled, (state) => {
            state.updatePasswordState = SliceStatus.SUCCEDED;
            state.error = undefined;
        });
        builder.addCase(resetPassword.rejected, (state) => {
            state.updatePasswordState = SliceStatus.FAILED;
        });
    },
});

export const selectUser = (state: RootState) => state.authSlice.user;
export const selectIsAuthLoading = (state: RootState) =>
    state.authSlice.status === SliceStatus.LOADING;
export const selectAuthError = (state: RootState) => state.authSlice.error;
export const { setUserErrorsAction, setUserToUndefined } = authSlice.actions;
export const selectUpdatePasswordStatus = (state: RootState) => state.authSlice.updatePasswordState;

export default authSlice.reducer;
