import React, { Suspense } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from '@material-ui/styles';

import { useDispatch } from 'react-redux';

import Header from './components/Header';
import Loader from './components/Loader';
import { theme } from 'constants/theme';
import { useUser } from 'context/userContext';
import AuthenticatedApp from 'Routes/AuthenticatedApp';
import UnauthenticatedApp from 'Routes/UnauthenticatedApp';
import { axiosInstance } from 'axiosInstance';
import { setUserToUndefined } from 'slices/authSlice';

const Layout = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const App = () => {
    const { user } = useUser();
    const dispatch = useDispatch();

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error: { response: { status: number } }) => {
            const { status } = error.response;
            if (status === 401) {
                dispatch(setUserToUndefined());
            }
            return Promise.reject(error);
        },
    );

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Suspense fallback={<Loader />}>
                    <Header />
                </Suspense>
                <Suspense fallback={<Loader />}>
                    {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
                </Suspense>
            </Layout>
        </ThemeProvider>
    );
};

export default App;
