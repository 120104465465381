import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {
    TextField,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogContentText,
    Switch,
    FormControlLabel,
} from '@material-ui/core';
import type { SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';

import { selectIsCreateUserLoading } from 'slices/createUserSlice';
import { useAppDispatch } from 'store';
import type { ApiErrorCode } from 'types/errorCodes';
import { selectSelectedUser, updateUser } from 'slices/userSlice';

const Container = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    min-width: 50vw;
    max-width: 50vw;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const FirstRowTextField = styled(TextField)`
    flex-basis: calc(50% - 1rem);
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
`;

const ActionButton = styled(Button)`
    flex-basis: calc(50% - 1rem);
`;

interface IProps {
    close?: () => void;
    userId: string | undefined;
}

type FormData = {
    firstName: string;
    lastName: string;
    email: string;
};

const EditUserSheet: React.FC<IProps> = ({ close, userId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const appDispatch = useAppDispatch();
    const selectedUser = useSelector(selectSelectedUser);

    const [isActive, setIsActive] = useState<boolean>(selectedUser?.isActive ?? true);

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<FormData>();

    const isLoading = useSelector(selectIsCreateUserLoading);

    const handleSetIsActive = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsActive(checked);
    };

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        if (!data.firstName || !data.lastName) {
            return;
        }

        const profile: {
            firstName: string;
            lastName: string;
        } = {
            firstName: data.firstName,
            lastName: data.lastName,
        };

        if (selectedUser) {
            await appDispatch(
                updateUser({
                    isActive,
                    profile,
                }),
            )
                .then(unwrapResult)
                .then(() => {
                    close?.();
                })
                .catch((errorCode: ApiErrorCode) => {
                    setErrorDialogTextKey(`Errors.${errorCode}`);
                    setOpenErrorDialog(true);
                });
        }
    };

    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
    const [errorDialogTextKey, setErrorDialogTextKey] = React.useState<string>('');

    const handleErrorDialogClose = () => {
        setOpenErrorDialog(false);
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="create-user-sheet-title">
                    {t('Components.EditUserSheet.title')}
                </DialogTitle>
                <DialogContent>
                    <FirstRow>
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue={selectedUser?.profile.firstName ?? ''}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FirstRowTextField
                                    label={t('Components.EditUserSheet.firstNameLabel')}
                                    error={errors.firstName !== undefined}
                                    helperText={
                                        errors.firstName !== undefined && t('Global.required')
                                    }
                                    required
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue={selectedUser?.profile.lastName ?? ''}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FirstRowTextField
                                    label={t('Components.EditUserSheet.lastNameLabel')}
                                    error={errors.lastName !== undefined}
                                    helperText={
                                        errors.lastName !== undefined && t('Global.required')
                                    }
                                    required
                                    {...field}
                                />
                            )}
                        />
                    </FirstRow>
                    <TextField
                        label={t('Components.EditUserSheet.emailLabel')}
                        style={{ width: '100%' }}
                        defaultValue={selectedUser?.email ?? ''}
                        disabled
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isActive}
                                onChange={handleSetIsActive}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={
                            isActive
                                ? t('Components.EditUserSheet.actif')
                                : t('Components.EditUserSheet.deactivated')
                        }
                    />
                    <ButtonContainer>
                        <ActionButton
                            variant="contained"
                            color="secondary"
                            onClick={close}
                            disabled={isLoading}
                        >
                            {t('Components.EditUserSheet.buttonCancel')}
                        </ActionButton>
                        <ActionButton
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={!isValid || isLoading}
                        >
                            {t('Components.EditUserSheet.buttonConfirm')}
                        </ActionButton>
                    </ButtonContainer>
                </DialogContent>
            </form>
            <Dialog
                open={openErrorDialog}
                onClose={handleErrorDialogClose}
                aria-labelledby="create-user-sheet-error-dialog"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t(errorDialogTextKey)}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default EditUserSheet;
