import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { max } from 'date-fns';
import styled from 'styled-components';

import type { WeeklyJournal } from 'types/user';
import { Flex1, Row } from 'styles/ReusabledStyledComponents';

interface IProps {
    journals: WeeklyJournal[];
}

const Container = styled.div`
    margin-horizontal: 2rem;
    display: flex;
    flex: 1;
    padding: 1rem;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subheading: {
            fontWeight: 'bold',
        },
    }),
);

const MaConsoHeader: React.FC<IProps> = ({ journals }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const SummaryData = React.useMemo(() => {
        const latestEntryTimes: Date[] = [];
        let numberOfInteractions = 0;

        journals.forEach((journal) => {
            if (journal.latestEntryTime) {
                latestEntryTimes.push(new Date(journal.latestEntryTime));
            }
            numberOfInteractions += journal.numberOfInteractions;
        });

        const firstEntryTime = journals[0]?.latestEntryTime
            ? new Date(journals[0].latestEntryTime).toLocaleString('en-gb')
            : '--';
        const maxLatestEntryTime: Date | null =
            latestEntryTimes.length > 0 ? max(latestEntryTimes) : null;
        const latestEntryTime = maxLatestEntryTime
            ? new Date(maxLatestEntryTime).toLocaleString('en-gb')
            : '--';

        return { firstEntryTime, latestEntryTime, numberOfInteractions };
    }, [journals]);

    return (
        <Container>
            <Row style={{ flex: 1 }}>
                <Flex1>
                    <Typography className={classes.subheading}>
                        {t('Components.MaConsoHeader.firstEntryTime')}
                    </Typography>
                    <Typography variant="caption">{SummaryData.firstEntryTime}</Typography>
                </Flex1>
                <Flex1>
                    <Typography className={classes.subheading}>
                        {t('Components.MaConsoHeader.latestEntryTime')}
                    </Typography>
                    <Typography variant="caption">{SummaryData.latestEntryTime}</Typography>
                </Flex1>

                <Flex1>
                    <Typography className={classes.subheading}>
                        {t('Components.MaConsoHeader.numberOfInteractions')}
                    </Typography>
                    <Typography variant="caption">{SummaryData.numberOfInteractions}</Typography>
                </Flex1>
            </Row>
        </Container>
    );
};

export default MaConsoHeader;
