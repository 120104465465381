import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import type { UserJournalRecap } from 'types/userJournal';
import type { WeeklyJournal } from 'types/user';
import MaConsoHeader from './MaConsoHeader';
import MaConsoBody from './MaConsoBody';
import QuestionAverages from './QuestionAverages';
import TitleWithSideLines from 'components/TitleWithSideLines';
import AccordianHeader from 'components/AccordianHeader';

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
`;

interface IProps {
    weeklyJournals?: WeeklyJournal[];
    userJournalsRecap?: UserJournalRecap | null;
}

const MaConso: React.FC<IProps> = ({ weeklyJournals = [], userJournalsRecap = null }) => {
    const { t } = useTranslation();

    if (weeklyJournals.length === 0) return null;

    return (
        <>
            <TitleWithSideLines title={t('Components.MaConsoHeader.title')} />

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <MaConsoHeader journals={weeklyJournals} />
                </AccordionSummary>
                <AccordionDetails>
                    <BodyContainer>
                        {weeklyJournals.map((journal) => (
                            <MaConsoBody key={journal.id} journal={journal} />
                        ))}
                    </BodyContainer>
                </AccordionDetails>
            </Accordion>
            {userJournalsRecap && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <AccordianHeader title={'MaConso.averagesTitle'} />
                    </AccordionSummary>
                    <AccordionDetails>
                        <BodyContainer>
                            <QuestionAverages userJournalsRecap={userJournalsRecap} />
                        </BodyContainer>
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
};

export default MaConso;
