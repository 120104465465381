import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import UserSegmentHeader from 'components/recaps/UserSegmentHeader';
import UserElementRecap from 'components/recaps/UserElementRecap';
import IncompleteMessage from 'components/recaps/IncompleteMessage';
import type { UserSegment } from 'types/userSegment';

// Types
import { Row, TitleSideLine } from 'styles/ReusabledStyledComponents';

interface IProps {
    title: string;
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TitleWithSideLines: React.FC<IProps> = ({ title }) => {
    const { t } = useTranslation();

    return (
        <Row style={{ alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
            <TitleSideLine />
            <Typography variant="h6">{title}</Typography>
            <TitleSideLine />
        </Row>
    );
};

export default TitleWithSideLines;
