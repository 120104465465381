import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {
    TextField,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogContentText,
} from '@material-ui/core';
import type { SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';

import { emailRegexp } from 'constants/regex';
import type { CreateUserType } from 'types/user';
import { Role } from 'types';
import { createUser, selectIsCreateUserLoading } from 'slices/createUserSlice';
import { useAppDispatch } from 'store';
import type { ApiErrorCode } from 'types/errorCodes';
import { fetchUsers } from 'slices/usersSlice';

const Container = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    min-width: 50vw;
    max-width: 50vw;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const FirstRowTextField = styled(TextField)`
    flex-basis: calc(50% - 1rem);
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
`;

const ActionButton = styled(Button)`
    flex-basis: calc(50% - 1rem);
`;

interface IProps {
    close?: () => void;
}

type FormData = {
    firstName: string;
    lastName: string;
    email: string;
};

const CreateUserSheet: React.FC<IProps> = ({ close }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const appDispatch = useAppDispatch();

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<FormData>();

    const isLoading = useSelector(selectIsCreateUserLoading);

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        if (!data.email || !data.firstName || !data.lastName) {
            return;
        }

        const payload: CreateUserType = {
            email: data.email,
            role: Role.PARTICIPANT,
            profile: {
                firstName: data.firstName,
                lastName: data.lastName,
            },
        };

        await appDispatch(createUser(payload))
            .then(unwrapResult)
            .then(() => {
                dispatch(fetchUsers());
                close?.();
            })
            .catch((errorCode: ApiErrorCode) => {
                setErrorDialogTextKey(`Errors.${errorCode}`);
                setOpenErrorDialog(true);
            });
    };

    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
    const [errorDialogTextKey, setErrorDialogTextKey] = React.useState<string>('');

    const handleErrorDialogClose = () => {
        setOpenErrorDialog(false);
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="create-user-sheet-title">
                    {t('Components.CreateUserSheet.title')}
                </DialogTitle>
                <DialogContent>
                    <FirstRow>
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FirstRowTextField
                                    label={t('Components.CreateUserSheet.firstNameLabel')}
                                    error={errors.firstName !== undefined}
                                    helperText={
                                        errors.firstName !== undefined && t('Global.required')
                                    }
                                    required
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FirstRowTextField
                                    label={t('Components.CreateUserSheet.lastNameLabel')}
                                    error={errors.lastName !== undefined}
                                    helperText={
                                        errors.lastName !== undefined && t('Global.required')
                                    }
                                    required
                                    {...field}
                                />
                            )}
                        />
                    </FirstRow>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{ required: true, pattern: emailRegexp }}
                        render={({ field }) => (
                            <TextField
                                label={t('Components.CreateUserSheet.emailLabel')}
                                style={{ width: '100%' }}
                                error={errors.email !== undefined}
                                helperText={
                                    errors.email !== undefined &&
                                    t('Components.CreateUserSheet.emailErrorText')
                                }
                                required
                                {...field}
                            />
                        )}
                    />
                    <ButtonContainer>
                        <ActionButton
                            variant="contained"
                            color="secondary"
                            onClick={close}
                            disabled={isLoading}
                        >
                            {t('Components.CreateUserSheet.buttonCancel')}
                        </ActionButton>
                        <ActionButton
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={!isValid || isLoading}
                        >
                            {t('Components.CreateUserSheet.buttonConfirm')}
                        </ActionButton>
                    </ButtonContainer>
                </DialogContent>
            </form>
            <Dialog
                open={openErrorDialog}
                onClose={handleErrorDialogClose}
                aria-labelledby="create-user-sheet-error-dialog"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t(errorDialogTextKey)}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default CreateUserSheet;
