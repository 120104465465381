import React from 'react';

import { AuthProvider } from './authContext';
import { UserProvider } from './userContext';

const AppProviders: React.FunctionComponent = ({ children }) => (
    <AuthProvider>
        <UserProvider>{children}</UserProvider>
    </AuthProvider>
);

export default AppProviders;
