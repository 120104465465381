import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { axiosInstance } from 'axiosInstance';
import { API_ROUTES } from 'constants/api';
import { SliceStatus } from 'types';
import type { ApiErrorCode } from 'types/errorCodes';
import { isApiError } from 'types/errorCodes';
import type { UserJournalRecap } from 'types/userJournal';

import type { RootState } from '../store';

export interface IUserJournalState {
    userJournalRecap: UserJournalRecap | null;
    status: SliceStatus;
    error?: ApiErrorCode;
}

export const initialState: IUserJournalState = {
    status: SliceStatus.IDLE,
    userJournalRecap: null,
};

interface FetchUserJournalRecapSuccessResponse {
    userJournalRecap: UserJournalRecap | null;
}

export const fetchUserJournalData = createAsyncThunk<
    FetchUserJournalRecapSuccessResponse,
    { userId: number },
    { rejectValue: ApiErrorCode }
>('user_segment/fetchUserJournalRecap', async (params: { userId: number }, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get<FetchUserJournalRecapSuccessResponse>(
            `${API_ROUTES.USER_JOURNAL}recap/${params.userId}`,
        );
        return response.data;
    } catch (err: unknown) {
        let apiErrorCode: ApiErrorCode = 'Generic';

        if (axios.isAxiosError(err) && err.response && isApiError(err.response.data)) {
            const body = err.response.data;
            apiErrorCode = body.errorCode;
        }

        return rejectWithValue(apiErrorCode);
    }
});

const userJournalSlice = createSlice({
    name: 'userJournal',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchUserJournalData.pending, (state) => {
            state.status = SliceStatus.LOADING;
        });
        builder.addCase(fetchUserJournalData.fulfilled, (state, { payload }) => {
            state.userJournalRecap = payload.userJournalRecap;
            state.status = SliceStatus.SUCCEDED;
            state.error = undefined;
        });
        builder.addCase(fetchUserJournalData.rejected, (state, action) => {
            state.status = SliceStatus.FAILED;
            state.error = action.payload;
            state.userJournalRecap = null;
        });
    },
});

export const selectUserJournals = (state: RootState) => state.userJournalSlice.userJournalRecap;

export default userJournalSlice.reducer;
