import { Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { SectionStatsKeys, ConsumptionStatsKeys } from 'types/stats';
import { selectStats } from 'slices/statsSlice';

const Row = styled.div`
    display: flex;
    width: 100%;
    margin: 1rem 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const PaperCard = styled(Paper)`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    flex-basis: 45%;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: white;
`;

const StatsContainer: React.FC = () => {
    const { t } = useTranslation();

    const stats = useSelector(selectStats);

    const sectionStatsBody = () => {
        if (!stats) return null;

        const keys = Object.keys(stats.sectionsStats) as SectionStatsKeys[];

        return (
            <Row>
                {keys.map((sectionKey) => {
                    const title = t(`Components.StatsContainer.CompletedSections.${sectionKey}`);
                    return CardWithUsersCount(title, stats.sectionsStats[sectionKey]);
                })}
            </Row>
        );
    };

    const consumptionStatsBody = () => {
        if (!stats) return null;

        const keys = Object.keys(stats.consumptionFrequencyStats) as ConsumptionStatsKeys[];

        return (
            <Row>
                {keys.map((sectionKey) => {
                    const title = t(`Components.StatsContainer.Consumption.${sectionKey}`);
                    return Card(title, stats.consumptionFrequencyStats[sectionKey]);
                })}
            </Row>
        );
    };

    const Card = (title: string, body: number) => (
        <PaperCard elevation={2} key={title}>
            <Typography component="span" variant="subtitle1">
                {title}
            </Typography>
            <Typography component="span" variant="h5">
                {body}
            </Typography>
        </PaperCard>
    );

    const CardWithUsersCount = (title: string, body: number) => (
        <PaperCard elevation={2} key={title}>
            <Typography component="span" variant="subtitle1">
                {title}
            </Typography>
            <Typography component="span" variant="h5">
                {body}/{stats?.activeUsers}
            </Typography>
        </PaperCard>
    );

    return (
        <>
            {!stats && null}
            <Row>
                {Card(
                    t('Components.StatsContainer.journalUseTitle'),
                    stats?.journalInteractions ?? 0,
                )}
            </Row>
            <Typography variant="h5">
                {t('Components.StatsContainer.CompletedSections.title')}
            </Typography>
            {sectionStatsBody()}
            <Typography variant="h5">{t('Components.StatsContainer.Consumption.title')}</Typography>
            {consumptionStatsBody()}
        </>
    );
};

export default StatsContainer;
