import { createTheme } from '@material-ui/core/styles';

import { colours } from 'constants/colours';

export const theme = createTheme({
    palette: {
        primary: {
            main: colours.darkBlue,
        },
        secondary: {
            main: colours.red,
        },
    },
});
