export const colours = {
    black: '#000000',
    transparent: '#ffffff00',
    transparentBlack90: '#00000090',

    // primary colors
    turquoise: '#59FF82',
    darkBlue: '#151268',
    darkBlue50: '#15126850',
    white: '#ffffff',
    blue: '#2A4BFF',

    // secondary colors
    purple: '#8A96FF',
    yellow: '#FABC58',
    green: '#2EAEA7',
    red: '#EB5551',

    // background
    lightBlue: '#DCF8FE',
    lightPurple: '#EEF0FF',
    lightYellow: '#F9EEDD',
    lightGreen: '#E2FFFA',
    lightGrey: '#8988B3',

    grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
    },
};
