import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import { AppBar, Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import Home from '@material-ui/icons/Home';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/svg/Vector.svg';

import { useUser } from 'context/userContext';
import { colours } from '../constants/colours';
import { logout } from 'slices/authSlice';

const ToolBar = styled(Toolbar)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Header: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useUser();
    const history = useHistory();

    const logOut = () => {
        dispatch(logout());
    };

    const navigateToHome = () => {
        history.push(`/`);
    };
    return user ? (
        <AppBar position="static">
            <ToolBar>
                <IconButton
                    style={{ flex: 1, maxWidth: 100 }}
                    onClick={navigateToHome}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                >
                    <Logo style={{ flex: 1, maxWidth: 100 }} height={40} width={'auto'} />
                </IconButton>

                <Button style={{ flex: 1, maxWidth: 100 }} onClick={logOut} color="inherit">
                    {t('Header.logout')}
                </Button>
            </ToolBar>
        </AppBar>
    ) : null;
};

export default Header;
