import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import type { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import type { User } from 'types/user';
import { axiosInstance } from 'axiosInstance';
import { API_ROUTES } from 'constants/api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(14),
            paddingRight: 12,
            fontWeight: 'bold',
        },
    }),
);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem;
`;
const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    paddingvertical: 1rem;
`;

interface IProps {
    user: User;
}

const UserDetails: React.FC<IProps> = ({ user }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [isLoading, setLoading] = React.useState(false);

    const resendEmail = async () => {
        const endpoint = API_ROUTES.SEND_CREATE_PASSWORD_EMAIL.replace(':userId', String(user.id));
        setLoading(true);

        try {
            await axiosInstance.post(endpoint);
        } catch (e: unknown) {
            // Do nothing
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <InfoContainer>
                <Row>
                    <Container>
                        <Typography className={classes.heading}>
                            {t('Components.UserDetails.firstName')}
                        </Typography>
                        <Typography variant="body2"> {user.profile.firstName}</Typography>
                    </Container>
                    <Container>
                        <Typography className={classes.heading}>
                            {t('Components.UserDetails.lastName')}
                        </Typography>
                        <Typography variant="body2">{user.profile.lastName}</Typography>
                    </Container>
                    <Container>
                        <Typography className={classes.heading}>
                            {t('Components.UserDetails.email')}
                        </Typography>
                        <Typography variant="body2">{user.email}</Typography>
                    </Container>
                </Row>
                <Row>
                    <Container>
                        <Typography className={classes.heading}>
                            {t('Components.UserDetails.firstSignIn')}
                        </Typography>
                        <Typography variant="body2">
                            {user.profile.firstSignIn
                                ? new Date(user.profile.firstSignIn).toLocaleString('en-gb')
                                : '--'}
                        </Typography>
                    </Container>
                    <Container>
                        <Typography variant="subtitle2" className={classes.heading}>
                            {t('Components.UserDetails.lastSignIn')}
                        </Typography>
                        <Typography variant="body2">
                            {user.profile.lastSignIn
                                ? new Date(user.profile.lastSignIn).toLocaleString('en-gb')
                                : '--'}
                        </Typography>
                    </Container>
                    <Container>
                        <Typography variant="subtitle2" className={classes.heading}>
                            {t('Components.UserDetails.accountStatus')}
                        </Typography>
                        <Typography variant="body2">
                            {user.isActive
                                ? t('Components.UserDetails.active')
                                : t('Components.UserDetails.deactivated')}
                        </Typography>
                    </Container>
                </Row>
                {!user.finishedSetup && (
                    <Row>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            onClick={resendEmail}
                            disabled={isLoading}
                        >
                            {t('Components.UserDetails.resendPasswordEmailButton')}
                        </Button>
                    </Row>
                )}
            </InfoContainer>
        </Container>
    );
};

export default UserDetails;
