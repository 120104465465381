import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles } from '@material-ui/styles';
import type { Theme } from '@material-ui/core';

import { Flex1, GrayInnerContainer, RecapTitle, Row } from 'styles/ReusabledStyledComponents';

import type { WeeklyJournal } from 'types/user';

interface IProps {
    journal: WeeklyJournal;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 'bolder',
        },
    }),
);

const MaConsoBody: React.FC<IProps> = ({ journal }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <GrayInnerContainer style={{ marginBottom: 10 }}>
            <RecapTitle>
                <Typography className={classes.title}>
                    {t('Components.MaConsoBody.weekTitle', { weekNumber: journal.weekNumber })}
                </Typography>
            </RecapTitle>
            <Row style={{ padding: '1rem' }}>
                <Flex1>
                    <Typography>{t('Components.MaConsoBody.startsOn')}</Typography>
                    <Typography variant="caption">
                        {new Date(journal.firstDayOfWeek).toLocaleDateString('en-gb', {})}
                    </Typography>
                </Flex1>
                <Flex1>
                    <Typography>{t('Components.MaConsoBody.firstEntryTime')}</Typography>
                    <Typography variant="caption">
                        {journal.firstEntryTime
                            ? new Date(journal.firstEntryTime).toLocaleString('en-gb')
                            : '--'}
                    </Typography>
                </Flex1>
                <Flex1>
                    <Typography>{t('Components.MaConsoBody.latestEntryTime')}</Typography>
                    <Typography variant="caption">
                        {journal.latestEntryTime
                            ? new Date(journal.latestEntryTime).toLocaleString('en-gb')
                            : '--'}
                    </Typography>
                </Flex1>
                <Flex1>
                    <Typography>{t('Components.MaConsoBody.numberOfInteractions')}</Typography>
                    <Typography variant="caption">{journal.numberOfInteractions}</Typography>
                </Flex1>
                <Flex1>
                    <Typography>{t('Components.MaConsoBody.daysOfConsumption')}</Typography>
                    <Typography variant="caption">{journal.daysOfConsumption ?? 0}</Typography>
                </Flex1>
            </Row>
        </GrayInnerContainer>
    );
};

export default MaConsoBody;
