import { Redirect, Route, Switch } from 'react-router-dom';

import UserDetailsPage from 'pages/userDetails/UserDetailsPage';
import HomePage from 'pages/home/HomePage';

const AuthenticatedApp = () => (
    <Switch>
        <Route path="/" exact>
            <HomePage />
        </Route>
        <Route path="/users/:userId">
            <UserDetailsPage />
        </Route>
        <Redirect to={'/'} />
    </Switch>
);

export default AuthenticatedApp;
