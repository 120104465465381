import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useSelector } from 'react-redux';

import UserSegmentHeader from 'components/recaps/UserSegmentHeader';
import UserElementRecap from 'components/recaps/UserElementRecap';
import IncompleteMessage from 'components/recaps/IncompleteMessage';
import type { UserSegment, UserChallenge } from 'types/userSegment';

// Types
import TitleWithSideLines from 'components/TitleWithSideLines';
import { selectUserSegments } from 'slices/userSegmentSlice';

interface IProps {
    userSegmentRecap: UserSegment;
    challengeOrder?: number;
    seRenforcerChallenge?: UserChallenge;
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const UserSegmentRecap: React.FC<IProps> = ({
    userSegmentRecap,
    challengeOrder,
    seRenforcerChallenge,
}) => {
    const { t } = useTranslation();
    let userChallenge: UserChallenge | null = null;
    if (seRenforcerChallenge) {
        userChallenge = seRenforcerChallenge;
    }
    const userSegmentsRecap = useSelector(selectUserSegments);

    const seMettreEnActionAssociatedWithChallenge = userSegmentsRecap?.seMettreEnAction?.find(
        (seMettreEnAction) => seRenforcerChallenge?.userSegmentId === seMettreEnAction.id,
    );

    return (
        <>
            {seMettreEnActionAssociatedWithChallenge ? (
                <TitleWithSideLines
                    title={`  ${t(`UserDetails.${userSegmentRecap.segmentType}`)} - ${t(
                        'UserDetails.challenge',
                        {
                            challengeOrder: userSegmentRecap.version,
                            seMettreVersion: seMettreEnActionAssociatedWithChallenge.version,
                        },
                    )}`}
                />
            ) : (
                <TitleWithSideLines
                    title={`  ${t(`UserDetails.${userSegmentRecap.segmentType}`)} - ${t(
                        'UserDetails.version',
                        { version: userSegmentRecap.version },
                    )}`}
                />
            )}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    style={{ alignItems: 'center' }}
                >
                    <Container style={{ padding: '1rem' }}>
                        <UserSegmentHeader
                            title={t(`UserDetails.${userSegmentRecap.segmentType}`)}
                            version={userSegmentRecap.version}
                            startedAt={userSegmentRecap.startedAt}
                            completedAt={userSegmentRecap.completedAt}
                            totalTime={userSegmentRecap.userSegmentTime?.[0]?.totalTime ?? 0}
                            challengeOrder={challengeOrder ?? null}
                        />
                    </Container>
                </AccordionSummary>
                <AccordionDetails>
                    <Container style={{ padding: '2rem' }}>
                        {userSegmentRecap.startedAt &&
                        userSegmentRecap.userElements &&
                        userSegmentRecap.userElements.length >= 1 ? (
                            <div>
                                {userSegmentRecap.userElements.map((userElement) => (
                                    <UserElementRecap
                                        key={`${userSegmentRecap.segmentType}_${userSegmentRecap.version}_${userElement.elementType}_${userElement.elementRank}`}
                                        segmentType={userSegmentRecap.segmentType}
                                        userElement={userElement}
                                        userChallenge={userChallenge}
                                    />
                                ))}
                            </div>
                        ) : (
                            <IncompleteMessage
                                message={
                                    userChallenge
                                        ? t(`UserDetails.challengeStatus.${userChallenge.status}`)
                                        : t('UserDetails.incomplete')
                                }
                            />
                        )}
                    </Container>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default UserSegmentRecap;
