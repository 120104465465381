export type ApiErrorCode =
    | 'NoPermission'
    | 'MissingOrInvalidUser'
    | 'AuthInvalidPasswordFormat'
    | 'DuplicatedUser'
    | 'Generic';

export type ServerErrorCode = ApiErrorCode;
export interface ApiError {
    status: number;
    errorCode: ApiErrorCode;
    message: string;
}
export interface ServerError {
    status: number;
    errorCode: ApiErrorCode;
    message: string;
}

export const isServerError = (object?: unknown): object is ServerError =>
    Object.prototype.hasOwnProperty.call(object, 'status') &&
    Object.prototype.hasOwnProperty.call(object, 'errorCode') &&
    Object.prototype.hasOwnProperty.call(object, 'message');

export const isApiError = (object?: unknown): object is ApiError =>
    Object.prototype.hasOwnProperty.call(object, 'status') &&
    Object.prototype.hasOwnProperty.call(object, 'errorCode') &&
    Object.prototype.hasOwnProperty.call(object, 'message');
