import { Redirect, Route, Switch } from 'react-router-dom';

import LoginPage from 'pages/login/LoginPage';
import CreatePasswordPage from 'pages/createPassword/CreatePasswordPage';
import ResetPasswordPage from 'pages/resetPassword/ResetPasswordPage';
import PasswordChangedPage from 'pages/passwordChanged/PasswordChangedPage';

const UnauthenticatedApp = () => (
    <Switch>
        <Route exact path="/login">
            <LoginPage />
        </Route>
        <Route exact path="/reset-password/:token">
            <ResetPasswordPage />
        </Route>
        <Route exact path="/create-password/:token">
            <CreatePasswordPage />
        </Route>
        <Route exact path="/password-changed/">
            <PasswordChangedPage />
        </Route>
        <Redirect to={'/login'} />
    </Switch>
);

export default UnauthenticatedApp;
