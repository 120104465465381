export type UserSegment = {
    id: number;
    segmentType: SegmentType;
    createdAt: string;
    startedAt: string | null;
    completedAt: string | null;
    version: number;
    userElements?: UserElement[];
    userSegmentTime?: UserSegmentTime[];
    previousVersions?: UserSegment[];
};

export type UserElement = {
    id: number;
    order: number;
    elementType: ElementType;
    elementRank: number;
    createdAt: string;
    startedAt: string | null;
    completedAt: string | null;
    userElementResponses?: UserElementResponse[];
    userSegmentId: number;
    lastViewedAt?: string | null;
    totalViews?: number | null;
};

export enum SegmentType {
    SE_SITUER = 'seSituer',
    SE_MOBILISER = 'seMobiliser',
    SE_METTRE_EN_ACTION = 'seMettreEnAction',
    SE_RENFORCER = 'seRenforcer',
}

export enum ElementType {
    MESSAGE = 'message',
    REDIRECT_MESSAGE = 'redirectMessage',
    BUTTON_QUIZ = 'buttonQuiz',
    CHANGE_PATH_QUIZ = 'changePathQuiz',
    MULTI_QUIZ_RECAP = 'multiQuizRecap',
    INFO_CARD = 'infoCard',
    CAROUSEL_INFO = 'carouselInfo',
    LARGE_CAROUSEL_INFO = 'largeCarouselInfo',
    MULTI_CAROUSEL_INFO = 'multiCarouselInfo',
    CARD_LIST_INFO = 'cardListInfo',
    CHALLENGE_LAUNCH = 'challengeLaunch',
    SMART_QUIZ = 'smartQuiz',
    SMART_INPUT = 'smartInput',
    MULTI_INPUT = 'multiInput',
    SMART_RECAP = 'smartRecap',
    SMART_DEADLINE = 'smartDeadline',
    SMART_START_DATE_RECAP = 'smartDateStartRecap',
    END_MESSAGE = 'endMessage',
    REDIRECT_CHALLENGE_MESSAGE = 'redirectChallengeMessage',
    CHALLENGE_MESSAGE = 'challengeMessage',
    CHALLENGE_FEEDBACK = 'challengeFeedback',
    CHALLENGE_FEEDBACK_REDIRECT = 'challengeFeedbackRedirect',
    CHALLENGE_END_MESSAGE = 'challengeEndMessage',
}

export type UserSegmentTime = {
    userSegmentId: number;
    startTime: string | null;
    resumeTime: string | null;
    pauseTime: string | null;
    totalTime: number;
    attempts: number;
    corruptData: boolean;
};

export type UserSegmentRecap = {
    seSituer: UserSegment[] | null;
    seMobiliser: UserSegment[] | null;
    seMettreEnAction: UserSegment[] | null;
    seRenforcer: UserSegment[] | null;
    challenges: UserChallenge[] | null;
};

export interface UserChallenge {
    id: number;
    userSegmentId: number;
    startDate: string;
    endDate: string;
    status: ChallengeStatus;
    seRenforcerSegmentId: number;
}

export enum ChallengeStatus {
    FAILED = 'failed',
    SUCCEEDED = 'succeeded',
    CANCELLED = 'cancelled',
    STARTED = 'started',
}

// User Element Responses
export interface UserElementResponse {
    id: number;
    userElementId: number;
    questionNumber: number;
    translationKey: string;
    input: string | null;
    createdAt: string;
}

export enum ResponsePathName {
    CONTINUE_AND_COMPLETE = 'continueAndComplete', // Se Situer [redirectMessage0] 1st response
    DO_NOT_CONTINUE = 'doNotContinue', // Se Situer [redirectMessage0] 2nd response
    IS_REALISABLE = 'isRealisable', // Se Situer [redirectMessage0] 1st response
    NOT_REALISABLE_RESTART = 'notRealisableRestart', // Se Situer [redirectMessage0] 2nd response
    CHALLENGE_SUCCESS = 'succeeded',
    CHALLENGE_FAIL = 'failed',
    SAME_CHALLENGE = 'sameChallenge',
    NEW_CHALLENGE = 'newChallenge',
    PAUSE_CHALLENGE = 'pauseChallenge',
}

export enum QuizType {
    MULTI_RESPONSE = 'multiResponse', // A user may give multiple responses to a single question
    SINGLE_RESPONSE = 'singleResponse', // A user may a single responses to a question
}

export enum ResponseType {
    SELECT = 'select', // A user must select a predefined response dictated by the associated responseKey
    INPUT = 'input', // A user can input a custom response
    SELECT_ONLY_THIS = 'selectOnlyThis', // A user must select a predefined response dictated by the associated responseKey and if selected other responses to question are deselected
}

export interface QuestionResponse {
    translationKey: string; // The text key in translation file
    type: ResponseType;
    order?: number; // Order in which the list of responses should appear
}

export interface Question {
    translationKey: string;
    type: QuizType;
    responses: QuestionResponse[];
}

export interface CategoriesQuestion {
    translationKey: string;
    type: QuizType;
    categories: CategoryQuestion[];
}

export interface CategoryQuestion {
    name: string;
    responses: QuestionResponse[];
}
