import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { axiosInstance } from 'axiosInstance';
import { API_ROUTES } from 'constants/api';
import { SliceStatus } from 'types';
import type { ApiErrorCode } from 'types/errorCodes';
import { isApiError } from 'types/errorCodes';
import type { Stats } from 'types/stats';
import type { RootState } from '../store';

export interface IStatsState {
    stats?: Stats;
    status: SliceStatus;
    error?: string;
}

export const initialState: IStatsState = {
    status: SliceStatus.IDLE,
};

interface SuccessResponse {
    stats: Stats;
}

export const fetchStats = createAsyncThunk<
    SuccessResponse,
    undefined,
    { rejectValue: ApiErrorCode }
>('stats/fetchStats', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get<SuccessResponse>(API_ROUTES.STATS);
        return response.data;
    } catch (err: unknown) {
        let apiErrorCode: ApiErrorCode = 'Generic';

        if (axios.isAxiosError(err) && err.response && isApiError(err.response.data)) {
            const body = err.response.data;
            apiErrorCode = body.errorCode;
        }

        return rejectWithValue(apiErrorCode);
    }
});

const slice = createSlice({
    name: 'statsSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchStats.pending, (state) => {
            state.status = SliceStatus.LOADING;
        });
        builder.addCase(fetchStats.fulfilled, (state, { payload }) => {
            state.stats = payload.stats;
            state.status = SliceStatus.SUCCEDED;
            state.error = undefined;
        });
        builder.addCase(fetchStats.rejected, (state, action) => {
            state.status = SliceStatus.FAILED;
            state.error = action.payload;
        });
    },
});

export const selectStats = (state: RootState) => state.statsSlice.stats;
export default slice.reducer;
