import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, Modal, Typography } from '@material-ui/core';

import { fetchUser, selectSelectedUser } from 'slices/userSlice';

import { fetchUserJournalData, selectUserJournals } from 'slices/userJournalSlice';
import { fetchUserSegmentData, selectUserSegments } from 'slices/userSegmentSlice';
import EditUserSheet from 'components/EditUser/EditUserSheet';

import UserDetails from 'components/userDetails/UserDetails';
import UserSegmentRecap from 'components/recaps/UserSegmentRecap';
import UserSegmentHeader from 'components/recaps/UserSegmentHeader';

// Types
import type { UserSegment } from 'types/userSegment';
import MaConso from 'components/journal/MaConso';

// Contants
import { colours } from 'constants/colours';

const PageContainer = styled.div`
    background-color: ${colours.grey[100]};
    height: 100%;
    padding: 2rem;
    overflow: scroll;
`;

const Container = styled.div``;
const UserDetailsContainer = styled(Paper)`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: white;
    padding: 2rem;
`;

const SegmentContainer = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const PreviousVersionContainer = styled.div`
    padding: 2rem;
    background-color: #e8e8e8;
    padding-right: 4rem;
`;

const TitleAndButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
interface UserParams {
    userId: string | undefined;
}

const UserDetailsPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { userId } = useParams<UserParams>();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (!userId) {
            history.goBack();
        } else {
            dispatch(fetchUserJournalData({ userId: Number(userId) }));
            dispatch(fetchUserSegmentData({ userId: Number(userId) }));
            dispatch(fetchUser({ userId: Number(userId) }));
        }
    }, [dispatch, history, userId]);
    const userJournalsRecap = useSelector(selectUserJournals);
    const userSegmentsRecap = useSelector(selectUserSegments);
    const user = useSelector(selectSelectedUser);
    const [showEditUser, setShowEditUser] = useState<boolean>(false);
    return (
        <>
            <Helmet>
                <title>{t('UserDetails.helmetTitle')}</title>
            </Helmet>
            <PageContainer data-testid="user_details_page">
                <Container>
                    {user && (
                        <UserDetailsContainer data-testid="user_details">
                            <TitleAndButtonContainer>
                                <Typography variant="h6">
                                    {t('UserDetails.UserDetailsTitle')}
                                </Typography>
                                <IconButton
                                    onClick={() => setShowEditUser(true)}
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                >
                                    <EditIcon />
                                </IconButton>
                            </TitleAndButtonContainer>
                            <UserDetails user={user} />
                        </UserDetailsContainer>
                    )}
                    {userSegmentsRecap && (
                        <>
                            <SegmentContainer data-testid="user_se_situer_recaps">
                                {userSegmentsRecap.seSituer &&
                                    userSegmentsRecap.seSituer.length >= 1 &&
                                    userSegmentsRecap.seSituer.map((userSegment: UserSegment) => (
                                        <UserSegmentRecap userSegmentRecap={userSegment} />
                                    ))}
                            </SegmentContainer>
                            <SegmentContainer data-testid="user_se_mobilser_recaps">
                                {userSegmentsRecap.seMobiliser &&
                                    userSegmentsRecap.seMobiliser.length >= 1 &&
                                    userSegmentsRecap.seMobiliser.map(
                                        (userSegment: UserSegment) => (
                                            <UserSegmentRecap userSegmentRecap={userSegment} />
                                        ),
                                    )}
                            </SegmentContainer>
                            <SegmentContainer data-testid="user_se_mettre_en_action_recaps">
                                {userSegmentsRecap.seMettreEnAction &&
                                    userSegmentsRecap.seMettreEnAction.length >= 1 &&
                                    userSegmentsRecap.seMettreEnAction.map(
                                        (userSegment: UserSegment, index) => {
                                            const challengeAssociatedWithSeMettreEnAction =
                                                userSegmentsRecap.challenges?.filter(
                                                    (challenge) =>
                                                        challenge.userSegmentId === userSegment.id,
                                                );

                                            if (
                                                challengeAssociatedWithSeMettreEnAction &&
                                                challengeAssociatedWithSeMettreEnAction.length > 0
                                            ) {
                                                return (
                                                    <>
                                                        <UserSegmentRecap
                                                            key={`${userSegment.segmentType}_${userSegment.version}`}
                                                            userSegmentRecap={userSegment}
                                                        />
                                                        {challengeAssociatedWithSeMettreEnAction.length >
                                                            0 &&
                                                            challengeAssociatedWithSeMettreEnAction.map(
                                                                (challenge, challengeIndex) => {
                                                                    const seRenforcersAssociatedWithThisChallenge =
                                                                        userSegmentsRecap.seRenforcer?.find(
                                                                            (seRenforcer) =>
                                                                                seRenforcer.id ===
                                                                                challenge.seRenforcerSegmentId,
                                                                        );

                                                                    if (
                                                                        seRenforcersAssociatedWithThisChallenge
                                                                    ) {
                                                                        return (
                                                                            <Container
                                                                                style={{
                                                                                    marginTop: 15,
                                                                                }}
                                                                            >
                                                                                <UserSegmentRecap
                                                                                    key={`seRenforcer_${userSegment.version}`}
                                                                                    userSegmentRecap={
                                                                                        seRenforcersAssociatedWithThisChallenge
                                                                                    }
                                                                                    challengeOrder={
                                                                                        challengeIndex +
                                                                                        1
                                                                                    }
                                                                                    seRenforcerChallenge={
                                                                                        challenge
                                                                                    }
                                                                                />
                                                                            </Container>
                                                                        );
                                                                    } else {
                                                                        return <></>;
                                                                    }
                                                                },
                                                            )}
                                                    </>
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        },
                                    )}
                            </SegmentContainer>
                        </>
                    )}
                    <MaConso
                        weeklyJournals={user?.weeklyJournals}
                        userJournalsRecap={userJournalsRecap}
                    />
                </Container>
                <Modal
                    open={showEditUser}
                    aria-labelledby="create-user-modal"
                    aria-describedby="create-user-modal-description"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <EditUserSheet close={() => setShowEditUser(false)} userId={userId} />
                    </div>
                </Modal>
            </PageContainer>
        </>
    );
};

export default UserDetailsPage;
