import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { axiosInstance } from 'axiosInstance';
import { API_ROUTES } from 'constants/api';
import { SliceStatus } from 'types';
import type { ApiErrorCode } from 'types/errorCodes';
import { isApiError } from 'types/errorCodes';
import type { User } from 'types/user';
import type { RootState } from '../store';

export interface IUsersState {
    users: User[];
    status: SliceStatus;
    error?: ApiErrorCode;
}

const initialState: IUsersState | undefined = {
    status: SliceStatus.IDLE,
    users: [],
};

interface FetchUsersSuccessResponse {
    users: User[];
}
export const fetchUsers = createAsyncThunk<
    FetchUsersSuccessResponse,
    undefined,
    { rejectValue: ApiErrorCode }
>('users/fetchUsers', async (params, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get<FetchUsersSuccessResponse>(API_ROUTES.USERS);
        return response.data;
    } catch (err: unknown) {
        let apiErrorCode: ApiErrorCode = 'Generic';

        if (axios.isAxiosError(err) && err.response && isApiError(err.response.data)) {
            const body = err.response.data;
            apiErrorCode = body.errorCode;
        }

        return rejectWithValue(apiErrorCode);
    }
});

const usersSlice = createSlice({
    name: 'usersSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchUsers.pending, (state) => {
            state.status = SliceStatus.LOADING;
        });
        builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
            state.users = payload.users;
            state.status = SliceStatus.SUCCEDED;
            state.error = undefined;
        });
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.status = SliceStatus.FAILED;
            state.error = action.payload;
            state.users = [];
        });
    },
});

export const selectUsers = (state: RootState) => state.usersSlice.users;
export default usersSlice.reducer;
