import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './i18n';

// CSS reset
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/assets.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/reduce-motion.css';
import store from 'store';
import AppProviders from 'context';

const GlobalStyle = createGlobalStyle`
    html, #root {
        height: 100%;
        background-color: 'white';
    }

    body {
        height: 100%;
        margin: 0;
        font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`;

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppProviders>
                <Router>
                    <Route path="/" component={App} />
                </Router>
            </AppProviders>
            <GlobalStyle />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
