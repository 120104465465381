import type { Action, PayloadAction, CombinedState } from '@reduxjs/toolkit';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import type { IAuthState } from './slices/authSlice';
import authReducer, { logout } from './slices/authSlice';
import type { IUsersState } from './slices/usersSlice';
import type { IUserState } from './slices/userSlice';
import usersReducer from './slices/usersSlice';
import userReducer from './slices/userSlice';
import type { ICreateUserState } from './slices/createUserSlice';
import createUserReducer from './slices/createUserSlice';
import type { IUserSegmentState } from './slices/userSegmentSlice';
import type { IUserJournalState } from './slices/userJournalSlice';
import userSegmentReducer from './slices/userSegmentSlice';
import userJournalReducer from './slices/userJournalSlice';
import type { IStatsState } from './slices/statsSlice';
import statsReducer from './slices/statsSlice';

const appReducer = combineReducers({
    authSlice: authReducer,
    usersSlice: usersReducer,
    createUserSlice: createUserReducer,
    userSlice: userReducer,
    userSegmentSlice: userSegmentReducer,
    statsSlice: statsReducer,
    userJournalSlice: userJournalReducer,
});

const rootReducer = (
    state:
        | CombinedState<{
              authSlice: IAuthState;
              usersSlice: IUsersState;
              userSegmentSlice: IUserSegmentState;
              userSlice: IUserState;
              createUserSlice: ICreateUserState;
              statsSlice: IStatsState;
              userJournalSlice: IUserJournalState;
          }>
        | undefined,
    action: PayloadAction<boolean>,
) => {
    // Reset state on logout
    if (action.type === logout.fulfilled.type) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
