import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import type { GridCellParams, GridColDef, GridRowsProp, GridRowId } from '@material-ui/data-grid';
import { DataGrid } from '@material-ui/data-grid';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import { addMonths } from 'date-fns';

import { fetchUsers, selectUsers } from 'slices/usersSlice';
import CreateUserSheet from 'components/CreateUser/CreateUserSheet';
import { colours } from 'constants/colours';
import { fetchStats } from 'slices/statsSlice';
import StatsContainer from 'components/StatsContainer';

const Container = styled.section`
    grid-area: content;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colours.grey['50']};
    width: 100%;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
`;

const TableContainer = styled(Paper)`
    display: flex;
    flex: 1;
    width: 80%;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: white;
    min-height: 1000px;
`;

const ExpandableSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const SectionDivider = styled(Divider)`
    && {
        width: 80%;
        margin: 2rem 0;
    }
`;

const useStyles = makeStyles({
    root: {
        '& .table-header': {
            backgroundColor: colours.grey['100'],
        },
    },
});

const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const users = useSelector(selectUsers);
    const classes = useStyles();

    const [openStats, setOpenStats] = React.useState(false);

    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(fetchStats());
    }, [dispatch]);

    const [showCreateUser, setShowCreateUser] = React.useState(false);

    const handleOpen = () => {
        setShowCreateUser(true);
    };

    const handleClose = () => {
        setShowCreateUser(false);
    };

    const handleStatsClick = () => {
        setOpenStats(!openStats);
    };

    const navigateToUserDetails = (userId: GridRowId) => {
        history.push(`/users/${userId}`);
    };

    const userRows: GridRowsProp = React.useMemo(
        () =>
            users.map((user) => ({
                id: user.id,
                firstName: user.profile.firstName,
                lastName: user.profile.lastName,
                email: user.email,
                isActive: user.isActive
                    ? (t('HomePage.active') as string)
                    : (t('HomePage.deactivated') as string),
                cutAccessDate: user.profile.firstSignIn
                    ? addMonths(new Date(user.profile.firstSignIn), 2).toLocaleDateString(
                          'en-gb',
                          {},
                      )
                    : '--',
            })),
        [users, t],
    );

    const columns: GridColDef[] = [
        {
            field: 'firstName',
            headerName: t('HomePage.headerUserFirstName'),
            headerClassName: 'table-header',
            flex: 1,
            editable: false,
        },
        {
            field: 'lastName',
            headerName: t('HomePage.headerUserLastName'),
            headerClassName: 'table-header',
            flex: 1,
            editable: false,
        },
        {
            field: 'email',
            headerName: t('HomePage.headerUserEmail'),
            headerClassName: 'table-header',
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            editable: false,
        },
        {
            field: 'isActive',
            headerName: t('HomePage.accountStatus'),
            headerClassName: 'table-header',
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            editable: false,
        },
        {
            field: 'cutAccessDate',
            headerName: t('HomePage.cutAccessDate'),
            headerClassName: 'table-header',
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            editable: false,
        },
        {
            field: '',
            headerName: '.',
            headerClassName: 'table-header',
            sortable: false,
            editable: false,
            disableColumnMenu: true,
            align: 'center',
            flex: 0.6,
            renderCell: (params: GridCellParams) => (
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => navigateToUserDetails(params.id)}
                    >
                        {t('HomePage.buttonUserDetails')}
                    </Button>
                </strong>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>{t('HomePage.helmetTitle')}</title>
            </Helmet>
            <Container data-testid="home_page">
                <ExpandableSection>
                    <Typography variant="h4">{t('HomePage.generalStatistics')}</Typography>
                    <IconButton onClick={handleStatsClick}>
                        {openStats ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </ExpandableSection>
                <Collapse in={openStats} timeout="auto" unmountOnExit style={{ width: '80%' }}>
                    <StatsContainer />
                </Collapse>
                <SectionDivider />
                <Typography variant="h4">{t('HomePage.userTable')}</Typography>
                <TableContainer className={classes.root}>
                    <DataGrid rows={userRows} columns={columns} style={{ flex: 1 }} />
                </TableContainer>
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    {t('HomePage.buttonCreateUser')}
                </Button>
            </Container>
            <Modal
                open={showCreateUser}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                aria-labelledby="create-user-modal"
                aria-describedby="create-user-modal-description"
            >
                <div>
                    <CreateUserSheet close={handleClose} />
                </div>
            </Modal>
        </>
    );
};

export default HomePage;
