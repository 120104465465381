import React from 'react';

import { selectUser } from 'slices/authSlice';
import { useAppSelector } from 'store';

import type { UserWithPermissions } from 'types/user';

interface UserContextProps {
    user: UserWithPermissions;
}

const UserContext = React.createContext<Partial<UserContextProps>>({});

const UserProvider: React.FunctionComponent = ({ children }) => {
    const user = useAppSelector(selectUser);
    return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};

const useUser = () => {
    const context = React.useContext(UserContext);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Context might be undefined
    if (context === undefined) {
        throw new Error(`useUser must be used within a UserProvider`);
    }

    return context;
};

export { UserProvider, useUser };
