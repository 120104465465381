export enum Role {
    ADMIN = 'admin',
    PARTICIPANT = 'participant',
}

export enum SliceStatus {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    SUCCEDED = 'SUCCEDED',
    FAILED = 'FAILED',
}
